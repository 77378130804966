exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-app-tsx": () => import("./../../../src/pages/app.tsx" /* webpackChunkName: "component---src-pages-app-tsx" */),
  "component---src-pages-demo-gameplay-tsx": () => import("./../../../src/pages/demo/gameplay.tsx" /* webpackChunkName: "component---src-pages-demo-gameplay-tsx" */),
  "component---src-pages-demo-index-tsx": () => import("./../../../src/pages/demo/index.tsx" /* webpackChunkName: "component---src-pages-demo-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-new-password-tsx": () => import("./../../../src/pages/new-password.tsx" /* webpackChunkName: "component---src-pages-new-password-tsx" */),
  "component---src-pages-nfts-tsx": () => import("./../../../src/pages/nfts.tsx" /* webpackChunkName: "component---src-pages-nfts-tsx" */),
  "component---src-pages-whitepaper-tsx": () => import("./../../../src/pages/whitepaper.tsx" /* webpackChunkName: "component---src-pages-whitepaper-tsx" */)
}

